




















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { ADD_BANK_ACCOUNT } from '@/modules/organization/store';
import { AddBankAccount, Organization } from '@/modules/organization/types';
import IbanField from '@/modules/listOfAssets/components/IBANField.vue';

const OrganizationStore = namespace('organization');

@Component({
  components: { IbanField }
})
export default class AddBankAccountlDialog extends Vue {
  @Prop({ type: Object, required: true }) organization!: Organization;
  @OrganizationStore.Action(ADD_BANK_ACCOUNT) addBankAccount!: (params: AddBankAccount) => Promise<ApiResponse>;

  dialog: boolean = false;
  loading: boolean = false;
  error: any = null;

  value: AddBankAccount = {
    organization_id: this.organization.id,
    bank_account: {
      bank: this.organization.bankAccount?.bank || '',
      iban: this.organization.bankAccount?.iban || '',
      bic: this.organization.bankAccount?.bic || '',
    }
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (!dialog) return;

    this.value = {
      organization_id: this.organization.id,
      bank_account: {
        bank: this.organization.bankAccount?.bank || '',
        iban: this.organization.bankAccount?.iban || '',
        bic: this.organization.bankAccount?.bic || '',
      }
    };
  }

  cancel(observer?: any) {
    this.dialog = false;

    setTimeout(() => {
      this.error = null;
      observer && observer.reset();
    }, 200);
  }

  async save() {
    this.error = null;

    return await this.addBankAccount(this.value);
  }
}
