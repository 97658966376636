





























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { RELOCATE_ORGANIZATION } from '@/modules/organization/store';
import { Organization, RelocateOrganization } from '@/modules/organization/types';

const OrganizationStore = namespace('organization');

@Component({})
export default class ReloacteDialog extends Vue {
  @Prop({ type: Object, required: true }) organization!: Organization;
  @OrganizationStore.Action(RELOCATE_ORGANIZATION) relocate!: (params: RelocateOrganization) => Promise<ApiResponse>;

  dialog: boolean = false;
  loading: boolean = false;
  error: any = null;

  value: RelocateOrganization = {
    organization_id: this.organization.id,
    tax_number: this.organization.taxNumber,
    name: this.organization.name,
    additional: this.organization.additional,
    street: this.organization.street,
    city: this.organization.city,
    zip_code: this.organization.zipCode
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (!dialog) return;

    this.value = {
      organization_id: this.organization.id,
      tax_number: this.organization.taxNumber,
      name: this.organization.name,
      additional: this.organization.additional,
      street: this.organization.street,
      city: this.organization.city,
      zip_code: this.organization.zipCode
    };
  }

  cancel(observer?: any) {
    this.dialog = false;

    setTimeout(() => {
      this.error = null;
      observer && observer.reset();
    }, 200);
  }

  async save() {
    this.error = null;

    return await this.relocate(this.value);
  }
}
