













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class TextField extends Vue {
  @Prop() value?: any;
  @Prop() placeholder?: string;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: String, required: true }) label!: string;
  @Prop({ type: Boolean, default: false }) filled!: boolean;

  get file(): any {
    return this.value;
  }

  set file(file: any) {
    this.$emit('input', file);
  }
}
