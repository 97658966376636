














































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { ADD_LETTERHEAD } from '@/modules/organization/store';
import { AddLetterhead, Organization } from '@/modules/organization/types';
import FileField from '@/components/form/FileField.vue';

const OrganizationStore = namespace('organization');

const toBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = error => reject(error);
});

@Component({
  components: { FileField }
})
export default class AddLetterheadDialog extends Vue {
  @Prop({ type: Object, required: true }) organization!: Organization;
  @Prop({ type: String, required: false }) value!: string;
  @OrganizationStore.Action(ADD_LETTERHEAD) addLetterhead!: (params: AddLetterhead) => Promise<ApiResponse>;

  dialog: boolean = false;
  loading: boolean = false;
  error: any = null;

  letterhead: any = null;

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (!dialog) return;

    this.letterhead = null;
  }

  cancel(observer?: any) {
    this.dialog = false;

    setTimeout(() => {
      this.error = null;
      observer && observer.reset();
    }, 200);
  }

  success(letterhead: string) {
    this.$emit('input', letterhead);
    this.cancel();
  }

  async save() {
    this.error = null;

    return await this.addLetterhead({
      organization_id: this.organization.id,
      letterhead: await toBase64(this.letterhead)
    });
  }
}
