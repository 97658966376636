




























import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_LETTERHEAD } from '@/modules/organization/store';
import { Organization } from '../../types';
import { ApiResponse } from '@/components/types';
import AddLetterheadDialog from '@/modules/organization/admin/components/AddLetterheadDialog.vue';

const OrganizationStore = namespace('organization');

@Component({
  components: { AddLetterheadDialog }
})
export default class LetterheadCard extends Vue {
  @Prop({ type: Object, required: true }) organization!: Organization;

  @OrganizationStore.Action(FETCH_LETTERHEAD) fetch!: () => Promise<ApiResponse<string>>;

  letterhead: string = '';
  loaded: boolean = false;
  error: any = null;

  async created() {
    this.loaded = false;
    const { content, error } = await this.fetch();

    if (content) {
      this.letterhead = content;
    }

    if (error) {
      this.error = error;
    }

    this.loaded = true;
  }
}
