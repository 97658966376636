














































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { ADD_FAX_NUMBER } from '@/modules/organization/store';
import { AddFaxNumber, Organization } from '@/modules/organization/types';

const OrganizationStore = namespace('organization');

@Component({})
export default class AddFaxNumberDialog extends Vue {
  @Prop({ type: Object, required: true }) organization!: Organization;
  @OrganizationStore.Action(ADD_FAX_NUMBER) addNumber!: (params: AddFaxNumber) => Promise<ApiResponse>;

  dialog: boolean = false;
  loading: boolean = false;
  error: any = null;

  value: AddFaxNumber = {
    organization_id: this.organization.id,
    fax_number: this.organization.faxNumber || ''
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (!dialog) return;

    this.value = {
      organization_id: this.organization.id,
      fax_number: this.organization.faxNumber || ''
    };
  }

  cancel(observer?: any) {
    this.dialog = false;

    setTimeout(() => {
      this.error = null;
      observer && observer.reset();
    }, 200);
  }

  async save() {
    this.error = null;

    return await this.addNumber(this.value);
  }
}
