














































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ApiResponse } from '@/components/types';
import { ADD_EMAIL } from '@/modules/organization/store';
import { AddEmail, Organization } from '@/modules/organization/types';

const OrganizationStore = namespace('organization');

@Component({})
export default class AddEmailDialog extends Vue {
  @Prop({ type: Object, required: true }) organization!: Organization;
  @OrganizationStore.Action(ADD_EMAIL) addEmail!: (params: AddEmail) => Promise<ApiResponse>;

  dialog: boolean = false;
  loading: boolean = false;
  error: any = null;

  value: AddEmail = {
    organization_id: this.organization.id,
    email: this.organization.email || ''
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (!dialog) return;

    this.value = {
      organization_id: this.organization.id,
      email: this.organization.email || ''
    };
  }

  cancel(observer?: any) {
    this.dialog = false;

    setTimeout(() => {
      this.error = null;
      observer && observer.reset();
    }, 200);
  }

  async save() {
    this.error = null;

    return await this.addEmail(this.value);
  }
}
